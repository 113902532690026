<template>
  <div class="supernodes">

    <div class="h-banner">
      <div class="bg">
        <div class="h-title" :style="{fontSize: $i18n.locale === 'zh-cn' ? '66px' : '72px', lineHeight: $i18n.locale === 'zh-cn' ? '70px' : '65px'}">
          <!-- Roxe Chain Supernodes  -->
          {{$t('Supernodes.Banner')}}
        </div>
      </div>
    </div> 
 
    <div class="p3">
      <div class="p3-box">
        <div class="title">{{$t('Supernodes.Row_1._1')}}</div>
        <div class="list">
          <div class="item">
            <img src="../../assets/img/supernodesp31.png"/>
            <div class="title">{{$t('Supernodes.Row_1._2')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._3')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._4')}}</div>
          </div>

          <div class="item">
            <img src="../../assets/img/supernodesp32.png"/>
            <div class="title">{{$t('Supernodes.Row_1._5')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._6')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._7')}}</div>
          </div>

          <div class="item">
            <img src="../../assets/img/supernodesp33.png"/>
            <div class="title">{{$t('Supernodes.Row_1._8')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._9')}}</div>
            <div class="text">{{$t('Supernodes.Row_1._10')}}</div>
          </div>
        </div>
      </div>  
    </div>

    <div class="p9">
      <div class="title">{{$t('Supernodes.Row_2._1')}}</div>
      <div class="text">{{$t('Supernodes.Row_2._2-1')}}<br/>{{$t('Supernodes.Row_2._2-2')}}<br/>{{$t('Supernodes.Row_2._2-3')}}</div>
      <div class="become">
          <a @click="open">{{$t('Become')}}</a>
        </div>
    </div>

    <div class="p10">
      <div class="p10-box">
        <div class="title">{{$t('Supernodes.Row_3._1')}}</div>
        <div class="list">
          <div class="item">
            <img src="../../assets/img/supernodesp101.png"/>
            <div class="title">{{$t('Supernodes.Row_3._3')}}</div>
            <div class="text">{{$t('Supernodes.Row_3._4')}}</div>
          </div>

          <div class="item">
            <img src="../../assets/img/supernodesp102.png"/>
            <div class="title">{{$t('Supernodes.Row_3._5')}}</div>
            <div class="text">{{$t('Supernodes.Row_3._6')}}</div>
          </div>

          <div class="item">
            <img src="../../assets/img/supernodesp103.png"/>
            <div class="title">{{$t('Supernodes.Row_3._7')}}</div>
            <div class="text">{{$t('Supernodes.Row_3._8')}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="p4">
      <div class="left">
        <div class="left-box">
          <div class="title">{{$t('Supernodes.Row_4._1')}}</div>
          <div class="aitcle-box">
            <div class="logo">
              <img src="../../assets/img/supernodesp41.png">
            </div>
            <div class="aitcle">
              <p class="sub-title">{{$t('Supernodes.Row_4._2')}}</p>
              <p class="sub-text">{{$t('Supernodes.Row_4._3')}}</p>
              <p class="sub-text">{{$t('Supernodes.Row_4._4')}}</p>
            </div>
          </div>
          <div class="aitcle-box">
            <div class="logo">
              <img src="../../assets/img/supernodesp42.png">
            </div>
            <div class="aitcle">
              <p class="sub-title">{{$t('Supernodes.Row_4._5')}}</p>
              <p class="sub-text">{{$t('Supernodes.Row_4._6')}}</p>
              <p class="sub-text">{{$t('Supernodes.Row_4._7')}}</p>
            </div>
          </div>
        </div> 
      </div>
      <div class="right">
        <div class="title">{{$t('Supernodes.Row_5._1')}}</div>
        <div class="sub-title">{{$t('Supernodes.Row_5._2')}}</div>
        <div class="text">
          <img src="../../assets/img/p41.png"/>
          <p>{{$t('Supernodes.Row_5._3')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png"/>
          <p>{{$t('Supernodes.Row_5._4')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png"/>
          <p>{{$t('Supernodes.Row_5._5')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png"/>
          <p>{{$t('Supernodes.Row_5._6')}}</p>
        </div>
      </div>
    </div>

    <div class="p5">
      <div class="title">{{$t('Supernodes.Row_6._1')}}</div>
      <div class="text">{{$t('Supernodes.Row_6._2-1')}}<br/>{{$t('Supernodes.Row_6._2-2')}}<br/>{{$t('Supernodes.Row_6._2-3')}}</div>
      <div class="become">
          <a  @click="open">{{$t('Supernodes.Row_6._3')}}</a>
        </div>
    </div>
    <Supernodes ref="supernodes"></Supernodes>
  </div>
</template>

<script>
  import Supernodes from "../../components/applySupernode.vue";
  export default {
    components: {
      Supernodes
    },
    methods: {
      open () {
        this.$refs.supernodes.dialogVisible = true
      }
    }
  }
</script>
<style lang="less" scoped>
  .supernodes {
    .h-banner {
      height: 386px;
      overflow: hidden;
      color: #ffffff;
      position: relative;
      background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
      .bg {
        background: url('../../assets/img/iStock-466868368 1.png') no-repeat;
        height: 386px;
        overflow: hidden;
      }
      .h-title {
        font-family: ParmaPetit;
        font-size: 80px;
        line-height: 100.3%;
        text-align: center;
        margin: 160px 0px 20px;
      }
      .h-title1 {
        font-family: Louis George Cafe;
        font-size: 26px;
        line-height: 20px;
        text-align: center;
      }
    }

    .p1 {
      background-image: url('../../assets/img/p1bg.png');
      background-repeat: no-repeat;
      height: 809px;
      background-size: 100% 100%;
      padding-top: 230px;
      .p1box {
        width: 1110px;
        margin: 0 auto;
        .title {
          width: 510px;
          font-family: ParmaPetit;
          font-style: normal;
          font-weight: normal;
          font-size: 46px;
          line-height: 40px;
          color: #FFFFFF;
          margin-bottom: 30px;
        }
        .subtitle {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 20px;
          color: #F6F8FB;
          width: 349px;
          height: 40px;
          margin-bottom: 30px;
        }
        .button {
          display: flex;
        }
        .read {
          width: 195px;
          height: 44px;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          line-height: 44px;
          display: inline-block;
          text-align: center;
          color: #fff;
          &:hover {
            opacity: 0.8;
          }
        }
        .join {
          width: 187px;
          height: 44px;
          background: #4AACFD;
          border: 1px solid #4AACFD;
          margin-left: 20px;
          display: inline-block;
          text-align: center;
          line-height: 44px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    

    .p3 {
      background: #ffffff;
      // padding-bottom: 180px;
      .p3-box {
        width: 1110px;
        margin: 0 auto;
        height: 360px;
        .title {
          text-align: center;
          font-family: ParmaPetit;
          font-style: normal;
          font-weight: normal;
          font-size: 38px;
          line-height: 35px;
          color: #085DA3;
          padding-top: 64px;
          margin-bottom: 54px;
        }
        .list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            max-width: 350px;
            height: 350px;
            padding: 37px 30px 0;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            margin-bottom: 30px;
            img {
              width: 50px;
              height: 50px;
              margin-bottom: 20px;
            }
            .title {
              font-family: Louis George Cafe;
              font-size: 20px;
              line-height: 23px;
              color: #085DA3;
              margin-bottom: 27px;
              padding-top: 0px;
              text-align: left;
            }
            .text {
              font-family: Louis George Cafe;
              font-size: 16px;
              line-height: 20px;
              color: #4F4F4F;
            }
          }
        }
      }
    }

    .p9 {
      background: #F6F8FB;
      height: 594px;
      padding-top: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        text-align: center;
        color: #085DA3;
        margin-bottom: 35px;
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #818E9E;
        width: 394px;
        margin-bottom: 50px;
      }
      .become {
        // width: 194px;
        padding: 0 30px;
        height: 44px;
        line-height: 44px;
        background: #2F98EE;
        color: #FFFFFF;
        font-family: Louis George Cafe;
        font-size: 16px;
        text-align: center;
        a {
          cursor: pointer;
        }
        &:hover {
          opacity: 0.8;
        }
        // margin: 0 auto;
        // margin-top: 60px;
      }
    }

    .p10 {
      background: #ffffff;
      // padding-bottom: 180px;
      .p10-box {
        width: 1110px;
        margin: 0 auto;
        // height: 360px;
        .title {
          text-align: center;
          font-family: ParmaPetit;
          font-style: normal;
          font-weight: normal;
          font-size: 38px;
          line-height: 35px;
          color: #085DA3;
          padding-top: 64px;
          margin-bottom: 54px;
        }
        .list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            max-width: 350px;
            height: 350px;
            padding: 37px 30px 0;
            background: #FFFFFF;
            // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            margin-bottom: 30px;
            img {
              width: 50px;
              height: 50px;
              margin-bottom: 20px;
            }
            .title {
              font-family: Louis George Cafe;
              font-size: 20px;
              line-height: 23px;
              color: #085DA3;
              margin-bottom: 27px;
              padding-top: 0px;
              text-align: left;
            }
            .text {
              font-family: Louis George Cafe;
              font-size: 16px;
              line-height: 20px;
              color: #4F4F4F;
            }
          }
        }
      }
    }


    .p4 {
      display:flex;
      padding-bottom: 77px;
      height: 630px;
      .left {
        width: 46%;
        background: #1C70BD;
        // height: 460px;
        margin-top: -77px;
        height: 570px;
        .left-box {
          float: right;
          width: 446px;
          margin-right: 50px;
          .aitcle-box {
            display: flex;
            justify-content: flex-start;
            .logo {
              img {
                display: block;
                width: 64px;
              }
            }
            .aitcle {
              .sub-title {
                font-family: Louis George Cafe;
                font-size: 20px;
                line-height: 23px;
                color: #FFFFFF;
                margin-bottom: 15px;
                margin-top: 20px;
              }
              .sub-text {
                font-family: Louis George Cafe;
                font-size: 16px;
                line-height: 20px;
                color: #F6F8FB;
                margin-bottom: 33px;
              }
            }
          }
          .title {
            font-family: ParmaPetit;
            font-style: normal;
            font-weight: normal;
            font-size: 38px;
            line-height: 35px;
            color: #FFFFFF;
            padding-top: 70px;
            margin-bottom: 28px
          }
        }
      }
      .right {
        padding-left: 155px;
        margin-left: -100px;
        z-index: -1;
        width: calc(54% + 100px);
        background: #F6F8FB;
        padding-bottom: 30px;
        .title {
          font-family: ParmaPetit;
          font-style: normal;
          font-weight: normal;
          font-size: 38px;
          line-height: 100.3%;
          color: #085DA3;
          margin-top: 73px;
          margin-bottom: 25px;
        }
        .sub-title {
          margin-bottom: 29px;
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 20px;
          color: #4F4F4F;
        }
        .text {
          display: flex;
          img {
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-right: 20px;
          }
          p {
            font-family: Louis George Cafe;
            font-size: 16px;
            line-height: 160%;
            color: #4F4F4F;
            margin-bottom: 35px;
          }
        }
      }
    }

    .p5 {
      background: #fff;
      // height: 594px;
      padding-top: 90px;
      padding-bottom: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        text-align: center;
        color: #085DA3;
        margin-bottom: 35px;
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #818E9E;
        width: 394px;
        margin-bottom: 50px;
      }
      .become {
        // width: 194px;
        padding: 0 30px;
        height: 44px;
        line-height: 44px;
        background: #2F98EE;
        color: #FFFFFF;
        font-family: Louis George Cafe;
        font-size: 16px;
        text-align: center;
        a {
          cursor: pointer;
        }
        &:hover {
          opacity: 0.8;
        }
        // margin: 0 auto;
        // margin-top: 60px;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 1210px) {
    .supernodes .h-banner {
      .h-title {
        font-size: 40px!important;
        line-height: 40px!important;
      }
    }
    .supernodes .p1 {
      padding-left: 50px;
      padding-right: 50px;
      background-size: cover;
      .p1box {
        width: auto;
        .title {
          width: auto;
        }
        .subtitle {
          width: auto;
        }
        .button {
          flex-wrap: wrap;
          .read {
            margin-right: 20px;
            margin-bottom: 20px;
            &:hover {
              opacity: 0.8;
            }
          }
          .join {
            margin-left: 0px;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .supernodes .p2 {
      padding-left: 50px;
      padding-right: 50px;
      flex-wrap: wrap;
      width: auto;
      justify-content: center;
      .introduce {
        width: auto;
      }
      img {
        width: 100%;
        height: auto;
        // height: auto;
      }
    }
    .supernodes .p3 {
      .p3-box {
        padding-left: 50px;
        padding-right: 50px;
        width: auto;
        height: auto;
        .list {
          .item {
            width: 100%;
            // min-width: 320px;
            max-width: none;
            height: auto;
            padding-bottom: 37px;
          }
        }
      }
    }
    .supernodes .p10 {
      .p10-box {
        padding-left: 50px;
        padding-right: 50px;
        width: auto;
        height: auto;
        .list {
          .item {
            width: 100%;
            // min-width: 320px;
            max-width: none;
            height: auto;
            padding-bottom: 37px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
    .supernodes .p4 {
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      .left {
        width: 100%;
        padding: 0 30px;
        height: auto;
        padding-bottom: 30px;
        margin-top: 0px;
        .left-box {
          margin-right: 0px;
          width: auto;
          float: inherit;
        }
      }
      .right {
        padding-left: 0px;
        width: 100%;
        margin: 0;
        padding: 0 30px;
      }
    }
    .supernodes .p5 {
      padding-left: 50px;
      padding-right: 50px;
      .text {
        width: auto;
      }
    }
    .supernodes .p6 {
      padding-left: 50px;
      padding-right: 50px;
      .p6-box {
        width: auto;
        .list {
          flex-wrap: wrap;
          .item {
            width: auto;
            margin-bottom: 30px;
            height: auto;
          }
        }
      }
    }
    .supernodes .p9 {
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 160px;
      .text {
        width: auto;
      }
    }
    .supernodes .p4 {
      padding-left: 50px;
      padding-right: 50px;
      
    }
  }
</style>